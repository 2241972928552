<template>
    <div  class="navmb">
        <div  class="flex flexa">
            <el-icon :size="20" color="#FF6A00" class="icon">
                <Back class="astyle bgshadow" @click="$router.back()" />
            </el-icon>
            <span  class="">绑定CSI详情</span>
        </div>
    </div>
    <div class="h20"></div>
    <dl class=" bgf csi1">
        <!-- <dt class="col1582 font16 lh60 borbot marb10">绑定CSI号详情</dt> -->
        <dd>
            <span>CSI</span>
            <span class="colzhuti font14">{{ info.csiNumber }} </span>
        </dd>
        <dd>
            <span>公司名称</span>
            <span>{{ info.companyName }} </span>
        </dd>
        <dd>
            <span>支持产品</span>
            <span>{{ info.productName||'- -' }}</span>
        </dd>
        <dd>
            <span>身份</span>
            <span>{{ {1:'普通用户',2:'管理员'}[info.identity] }} &nbsp;&nbsp;

                <el-button @click="exCau.visible = true" v-if="info.identity == 2 && info.status == 2"
                    size="mini" type="primary" round>
                    <div class="flex flexa">
                        <img :src="utils.loadImg('user1.png')" width="12" height="12" alt="" srcset=""> &nbsp;
                        身份转让
                    </div>
                </el-button>
            </span>
        </dd>
        <dd>
            <span>状态</span>
            <span class="colblue">{{ ['', '待审核', '绑定成功', '审核失败'][info.status] }} &nbsp;&nbsp;&nbsp;&nbsp;
                <el-button v-if="info.status == 2" @click="unbind.visible = true" size="mini" type="danger" round>
                    <div class="flex flexa">
                        <img :src="utils.loadImg('cel.png')" width="14" alt="" srcset=""> &nbsp;
                        解除绑定
                    </div>
                </el-button>
            </span>
        </dd>

    </dl>
    <p class="h20"></p>
    <div class="pad20 bgf" v-if="info.status == 2 && userType==2">
        <p class="col1582 font16 lh60 borbot">人员管理</p>
        <div class="padt20 padb30">
            <el-table
                row-key="id"
                v-loading="systemUser.loading"
                :data="systemUser.list"
                :header-cell-style="{ background: '#F1F1F1', }"
                class="border"
                style="width: 100%"
            >
                <el-table-column fixed prop="username" label="用户名" min-width="50">
                </el-table-column>
                <el-table-column prop="mobile" show-overflow-tooltip label="手机号">
                </el-table-column>
                <el-table-column prop="userNo" label="个人编号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="departmentName" label="所属部门">
                </el-table-column>
                <el-table-column label="COE">
                    <template #="{ row }">
                        {{ isCeo.get(row.isCeo) }}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="绑定时间" min-width="150">
                </el-table-column>
                <el-table-column label="状态" min-width="120">
                    <template #="{ row }">
                        <span class="colblue ">{{ ['', '待审核', '绑定成功', '审核失败'][row.status] }}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150">
                    <template #="{ row }">
                        <template v-if="row.status == 2">
                            <span @click="toSysUserAdd(row)" class="colblue point">编辑</span>
                            <el-divider direction="vertical"></el-divider>
                        </template>
                        <span v-if="row.status == 1">
                            <span @click="auditorbangding(row.id, 2)" class="colblue point">通过</span>
                            <el-divider direction="vertical"></el-divider>  
                            <span @click="auditorbangding(row.id, 3)" class="colred point">拒绝</span>
                        </span>
                        <span v-else>
                            <span @click="sysUser_deleteRow(row)" class="colblue point">删除用户</span>
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="tc padt40 padb40 flex">
            <div class="flex1"></div>
            <el-pagination
                v-model:currentPage="systemUser.param.pageNumber"
                @update:page-size="systemUser.param.pageSize = $event;systemuserlist();"
                @update:current-page="systemuserlist"
                :page-sizes="[5, 10, 15, 20]"
                :page-size="systemUser.param.pageSize"
                layout=" prev, pager, next, sizes, jumper"
                :total="systemUser.total"
            >
            </el-pagination>
        </div>


    </div>
    <p class="h20"></p>
    <div class="pad20 bgf" v-if="info.status == 2 && userType==2">
        <div class="col1582 font16 lh60 borbot">
            <span>csi部门管理</span>
            <el-button type="primary" class="marl10" @click="depart.visible = true">+添加部门</el-button>
        </div>
        <div class="padt20 padb30">
            <el-table
                v-loading="depart.loading"
                row-key="id"
                :data="depart.list"
                :header-cell-style="{ background: '#F1F1F1', }"
                class="border"
                style="width: 100%">
                <el-table-column fixed prop="departmentTitle" label="部门名称" min-width="150"></el-table-column>
                <el-table-column prop="userNumbers" label="部门下工程师"  min-width="250"></el-table-column>
                <el-table-column prop="createTime" label="添加时间" min-width="200"></el-table-column>
                <el-table-column fixed="right" label="操作" width="240">
                    <template #="{ row }">
                        <el-button text type="primary" @click="depart.form = Object.assign({}, row);depart.visible = true" class="colblue point">编辑</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button text type="primary" class="colblue point" @click="loadingDepart(row)">管理</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button text type="danger" @click="depart_deleteRow(row)" :loading="row.deleteLoading??false">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="tc padt40 padb40 flex">
            <div class="flex1"></div>
            <el-pagination
                v-model:currentPage="depart.param.pageNumber"
                @update:page-size="depart.param.pageSize = $event;systemdepartmentlist();"
                @update:current-page="systemdepartmentlist"
                :page-sizes="[5, 10, 15, 20]"
                :page-size="depart.param.pageSize"
                layout=" prev, pager, next, sizes, jumper"
                :total="depart.total"
            >
            </el-pagination>
        </div>
    </div>
    <el-dialog :close-on-click-modal="false"
        title="人员信息管理"
        width="502px"
        v-model="sysUser.visible"
        align-center
        @closed="(sysUser.form = {}) && $refs.submitForm_sysUser.resetFields()"
    >
        <el-form
            :model="sysUser.form"
            ref="submitForm_sysUser"
            label-width="150px"
            :rules="sysUser.rules"
        >
                <el-form-item label="是否为团队COE" class="" prop="isCeo">
                    <el-radio-group v-model="sysUser.form.isCeo">
                        <el-radio value="2">是</el-radio>
                        <el-radio value="1">不是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="所属团队部门" prop="departmentId">
                    <el-select-v2
                        v-model="sysUser.form.departmentId"
                        placeholder="选择人员所属团队部门"
                        class="w300"
                        filterable
                        clearable
                        :props="{ label: 'departmentTitle', value: 'id' }"
                        :loading="vselect.depart.loading"
                        :options="vselect.depart.options"
                    />
                </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="sysUser.submitLoading??false" @click="submit_sysUser" class="w90">确认</el-button>
            <el-button @click="sysUser.visible = false" class="w90">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog :close-on-click-modal="false"
        title="部门管理"
        width="490px"
        v-model="depart.visible"
        align-center
        @closed="(depart.form = {}) && $refs.submitForm_depart.resetFields()"
    >
        <el-form
            :model="depart.form"
            ref="submitForm_depart"
            label-width="120px"
            :rules="depart.rules"
        >
            <div class="padl20">
                <el-form-item label="部门名称" prop="departmentTitle">
                    <el-input v-model="depart.form.departmentTitle"></el-input>
                </el-form-item>
            </div>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="depart.submitLoading??false" @click="submit_depart" class="w90">确认</el-button>
            <el-button @click="depart.visible = false" class="w90">取消</el-button>
        </template>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="分配CAU身份给其他人" width="490px" v-model="exCau.visible">
        <el-form
            :model="exCau.form"
            ref="submitForm_exCau"
            label-width="auto"
            :rules="exCau.rules"
        >
            <div class="padl20">
                <el-form-item prop="userId">
                    <el-select-v2
                        v-model="exCau.form.userId"
                        placeholder="选择公司的其它同事"
                        filterable
                        clearable
                        class="w350"
                        :props="{ value: 'userId', label: 'username'  }"
                        :loading="vselect.systemUser.loading"
                        :options="vselect.systemUser.options"
                    />
                </el-form-item>
                <p class="w350 font10 colb2 lh20 padt10">选择将身份分配给其他人，必须从当前公司已绑定的用户列表中进行选择，分配后你的身份将成为普通用户</p>
            </div>
        </el-form>
        <template #footer>
            <div class="tl padl20 padb10">
                <el-button :loading="exCau.submitLoading??false" size="small" type="primary" @click="distributioncau" class="w90">确认分配</el-button>
            </div>
        </template>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="您确定要解除当前绑定的CSI账号么？" width="490px" v-model="unbind.visible">
        <div class="padl10">
            <p class="col65">解除绑定后 则不能提交该CSI号类型下的技术工单</p>
        </div>
        <template #footer>
            <el-button @click="unbind.visible = false" class="w68">取 消</el-button>
            <el-button type="primary" :loading="unbind.submitLoading" @click="unbindCsi({ id: info.id })" class="w68">确 定</el-button>
        </template>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" align-center title="部门人员管理" width="600px" v-model="inDepart.show">
        <el-button type="primary" @click="inDepart.addShow = true">添加人员</el-button>
        <el-table :data="inDepart.list">
            <el-table-column label="用户名" prop="username"></el-table-column>
            <el-table-column label="操作" width="150">
                <template #="{ row }">
                    <el-button text type="danger" @click="deleteInDepart(row)" :loading="row.deleteLoading??false">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :close-on-click-modal="false"
            v-model="inDepart.addShow"
            width="500"
            title="添加人员"
            align-center
            append-to-body
            @closed="(inDepart.form = {}) && $refs.submitForm_inDepart.resetFields()"
        >
            <el-form
                :model="inDepart.form"
                ref="submitForm_inDepart"
                label-width="auto"
                :rules="inDepart.rules"
            >
                <el-form-item label="添加人员" prop="id">
                    <el-select
                        v-model="inDepart.form.id"
                    >
                        <el-option
                            v-for="item in systemUser.list"
                            :key="item.id"
                            :label="item.username"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button :loading="inDepart.form.loading??false" @click="$refs.submitForm_inDepart.validate($event => $event && addInDepart())" class="w68" type="primary">确定</el-button>
            </template>
        </el-dialog>
        <template #footer>
            <el-button @click="inDepart.show = false" class="w68">关 闭</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { Back } from '@element-plus/icons-vue'
import {
    bindingCsiInfo,
} from "@/utils/api1.js"


export default {
    setup() {
        return {
            isCeo: new Map([
                ['1', '否'],
                ['2', '是'],
            ])
        }
    },
    components: {
        Back
    },
    data() {
        return {
            id: this.$route.params.id.split(',')[0],
            userType: this.$route.params.id.split(',')[1],
            csiId: this.$route.params.id.split(',')[2],
            info: {
            },
            depart: {
                visible: false,
                form: {
                },
                param: {
                    companyId: '',
                    pageNumber: 1,
                    pageSize: 10,
                },
                list: [],
                total: 0,
                loading: false,
                submitLoading: false,
                fullscreenLoading: false,
                rules: {
                    departmentTitle: [{ required: true, message: '请填写完整的部门名称', trigger: 'blur' }],
                }
            },
            
            systemUser: {
                param: {
                    companyId: '',
                    pageNumber: 1,
                    pageSize: 10,
                    csiId: this.$route.params.id.split(',')[2],
                },
                list: [],
                total: 0,
                loading: false,
            },
            exCau: {
                visible: false,
                form: {

                },
                rules: {
                    userId: [{ required: true, message: '请选择', trigger: 'change' }],
                },
                submitLoading: false,
            },
            // 人员信息管理
            sysUser: {
                visible: false,
                form: {

                },
                rules: {
                    isCeo: [{ required: true, message: '请选择是否CEO', trigger: 'change' }],
                },
                submitLoading: false
            },
            unbind: {
                visible: false,
                submitLoading: false,
            },
            vselect: {
                systemUser: {
                    loading: false,
                    options: []
                },
                depart: {
                    loading: false,
                    options: []
                }
            },
            inDepart: {
                show: false,
                loading: false,
                param: {
                    pageNumber: 1,
                    pageSize: 10,
                },
                list: [],
                total: 0,
                addShow: false,
                form: {},
                rules: {
                    id: [{ required: true, message: '请选择', trigger: 'change' }],
                }
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async addInDepart() {
            try {
                this.inDepart.form.addLoading = true
                const result = await this.$api.user.addeditdsystemuserinfo({
                    departmentId: this.inDepart.param.id,
                    id: this.inDepart.form.id,
                })
                if (result) {
                    this.$message.success('操作成功')
                    this.inDepart.addShow = false
                    this.inDepart.param.pageNumber = 1
                    const { records, total } = await this.$api.user.departmentUserlist(this.inDepart.param)
                    this.inDepart.list = records
                    this.inDepart.total = total
                    this.systemdepartmentlist(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.inDepart.form.addLoading = false
            }
        },
        async deleteInDepart(row) {
            try {
                row.deleteLoading = true
                const result = await this.$api.user.addeditdsystemuserinfo({
                    departmentId: 0,
                    id: row.id,
                })
                if (result) {
                    this.$message.success('操作成功')
                    this.inDepart.param.pageNumber = 1
                    const { records, total } = await this.$api.user.departmentUserlist(this.inDepart.param)
                    this.inDepart.list = records
                    this.inDepart.total = total
                    this.systemdepartmentlist(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                row.deleteLoading = false
            }
        },
        async loadingDepart(row) {
            try {
                this.inDepart.param.id = row.id
                this.inDepart.loading = true
                const { records, total } = await this.$api.user.departmentUserlist(this.inDepart.param)
                this.inDepart.list = records
                this.inDepart.total = total
                this.inDepart.show = true
            } catch (e) {
                return console.log(e)
            } finally {
                this.inDepart.loading = false
            }
        },
        toSysUserAdd(row) {
            console.log(row, 'row')
            row.departmentId = `${ row.departmentId??'' }`
            this.sysUser.form = Object.assign({}, row)
            this.sysUser.visible = true
        },
        async vselect_depart(companyId) {
            try {
                this.vselect.depart.loading = true
                const { records, total } = await this.$api.user.systemdepartmentlist({
                    companyId,
                    pageNumber: 1,
                    pageSize: 2000
                })
                console.log('部门列表',records)
                this.vselect.depart.options = records
                this.vselect.depart.loading = false
                console.log(records, 'record')
            } catch (e) {
                this.vselect.depart.loading = false
                this.vselect.depart.options = []
            }
        },
        async vselect_systemUser(companyId) {
            try {
                this.vselect.systemUser.loading = true
                const { records, total } = await this.$api.user.systemuserlist({
                    companyId,
                    pageNumber: 1,
                    pageSize: 2000,
                    csiId:this.systemUser.param.csiId
                })
                this.vselect.systemUser.options = records.filter(item => item.status == 2)
                this.vselect.systemUser.loading = false
            } catch (e) {
                this.vselect.systemUser.loading = false
                this.vselect.systemUser.options = []
            }
        },
        async submit_sysUser() {
            try {
                this.sysUser.submitLoading = true
                await this.$refs['submitForm_sysUser'].validate()
                const result = await this.$api.user.addeditdsystemuserinfo({
                    departmentId: this.sysUser.form.departmentId,
                    id: this.sysUser.form.id,
                    isCeo: this.sysUser.form.isCeo
                })
                if (result) {
                    this.$message.success('操作成功')
                    this.sysUser.visible = false
                    this.systemuserlist(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.sysUser.submitLoading = false
            }
        },
        async submit_depart() {
            try {
                this.depart.submitLoading = this.depart.fullscreenLoading = true
                await this.$refs['submitForm_depart'].validate()
                const result = await this.$api.user.addeditdepartment({
                    companyId: this.info.companyId,
                    departmentTitle: this.depart.form.departmentTitle,
                    id: this.depart.form.id,
                    csiId: this.info.csiId
                })
                if (result) {
                    this.$message.success('操作成功')
                    this.depart.visible = false
                    this.systemdepartmentlist(1)
                    this.vselect_depart(this.info.companyId)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.depart.submitLoading = this.depart.fullscreenLoading = false
            }
        },
        async systemdepartmentlist(pageNumber) {
            try {
                pageNumber && (this.depart.param.pageNumber = pageNumber)
                this.depart.loading = true
                const { records, total } = await this.$api.user.systemdepartmentlist(this.depart.param)
                this.depart.list = records
                this.depart.total = total
            } catch (e) {
                return console.log(e)
            } finally {
                this.depart.loading = false
            }
        },
        async sysUser_deleteRow(row) {
            try {
                await this.$confirm(
                    `确认解绑吗`,
                    "信息",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }
                )
                row.deleteLoading = true
                const result = await this.$api.user.Unbindbangding({
                    id: row.id,
                })
                if (result) {
                    this.systemuserlist(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                row.deleteLoading = false
            }
        },
        async unbindCsi(row) {
            try {
                const result = await this.$api.user.Unbindbangding({
                    id: row.id,
                })
                if (result) {
                    this.$message.success(result.message);
                    this.$router.replace({
                        path: '/account'
                    });
                    this.unbind.visible = false
                }
            } catch (e) {
                return console.log(e)
            }
        },
        async depart_deleteRow(row) {
            try {
                await this.$confirm(
                    `确认删除"${ row.departmentTitle }"吗`,
                    "信息",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }
                )
                row.deleteLoading = true
                const result = await this.$api.user.delldepartment({
                    id: row.id,
                })
                if (result) {
                    this.systemdepartmentlist(1)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                row.deleteLoading = false
            }
        },
        async distributioncau() {
            try {
                this.exCau.submitLoading = true
                await this.$refs['submitForm_exCau'].validate()
                const result = await this.$api.user.distributioncau({
                    id: this.info.csiId,
                    userId: this.exCau.form.userId
                })
                if (result) {
                    this.$message.success('操作成功')
                    this.exCau.visible = false
                    this.getData()
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.exCau.submitLoading = false
            }
        },
        async getData() {
            bindingCsiInfo({ id: this.id }).then(res => {
                console.log(res, '--=');
                if (res) {
                    this.info = res;
                    if (res.status == 2) {
                        this.systemUser.param.companyId = res.companyId
                        this.depart.param.companyId = res.companyId
                        this.systemuserlist()
                        this.systemdepartmentlist()
                        this.vselect_systemUser(res.companyId)
                        this.vselect_depart(res.companyId)
                    }
                }
            });
        },
        async systemuserlist(pageNumber) {
            try {
                pageNumber && (this.systemUser.param.pageNumber = pageNumber)
                this.systemUser.loading = true
                console.log('this.systemUser.param',this.systemUser.param)
                const { records, total } = await this.$api.user.systemuserlist(this.systemUser.param)
                this.systemUser.list = records
                this.systemUser.total = total
                console.log(records, 'records, -')
            } catch (e) {
                return console.log(e)
            } finally {
                this.systemUser.loading = false
            }
        },
        async auditorbangding(id, status) {
            try {
                let txt = ['', '', '同意', '拒绝', '删除'][status];
                let title = '是否' + txt + '用户加入公司团队的申请？';
                let action = txt + '申请后也可以在公司团队中进行删除或者操作';
                await this.$confirm(action, title, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                })
                const result = await this.$api.user.auditorbangding({ id, status })
                if (result) {
                    this.$message.success(result.message)
                    this.getData()
                }
            } catch (e) {
                return console.log(e)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.csi1 {
    color: #272729;
    padding: 0 20px 35px;

    dd {
        padding-top: 32px;

        span:nth-child(1) {
            color: #656565;
            width: 110px;
            min-width: 110px;
            text-align: right;
            padding-right: 60px;
            display: inline-block;
        }
    }
}

.w350 {
    width: 350px;
}
</style>